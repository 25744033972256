import { asyncComponent } from '@jaredpalmer/after';

export default [
  {
    path: '/unauthorized',
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/Unauthorized'),
      chunkName: /* webpackChunkName: "Unauthorized" */"Unauthorized",
    }),
  },
  {
    path: '/',
    name: "Freelancers",
    exact: true,
    component: asyncComponent({
      loader: () => import('./pages/Freelancers'),
      chunkName: /* webpackChunkName: "Register" */"Register",
    }),
  },
  {
    path: "*",
    component: asyncComponent({
      loader: () => import("./pages/PageNotFound"),
      chunkName: /* webpackChunkName: "PageNotFound" */"PageNotFound",
    }),
  },
];
