import { createStore, applyMiddleware, compose } from 'redux';
import createReducers from '../reducers/create';
import thunk from 'redux-thunk';
import configurePromise from '../middleware/promise';
import asyncDispatch from "../middleware/asyncDispatch";
import reducerRegistry from "./registry";

export default function create(client, data) {
  const middleware = [thunk, configurePromise(client), asyncDispatch];
  const storeCreator = compose(
    applyMiddleware(...middleware),
  )(createStore);

  const _createReducers = (reducers) => createReducers(reducers, data);

  const store = storeCreator(_createReducers(reducerRegistry.getReducers()), data);
  reducerRegistry.setChangeListener(reducers => {
    store.replaceReducer(_createReducers(reducers));
  });

  return store;
}
