import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ensureReady, After } from '@jaredpalmer/after';
import routes from './routes';
import * as Sentry from '@sentry/browser';
import { Provider } from "react-redux";
import createStore from "./store/create";
import ApiClient from "./utils/ApiClient";
import Page from "./hoc/Page";

const client = new ApiClient({
  apiPath: "",
});

ensureReady(routes).then(data => {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: "https://9d84e277561849f7a66d0b070873ea51@o108854.ingest.sentry.io/5258878",
    });
  }

  const store = createStore(client, data.initialState);

  return hydrate(
    <Provider store={store}>
      <BrowserRouter>
        <Page>
          <After data={data} routes={routes} />
        </Page>
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
  );
});

if (module.hot) {
  module.hot.accept();
}
